.super_buyer {
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #00ccc7;
}

.super_buyer_star {
  width: 20px;
  height: 20px;
  color: #ffffff;
  background: #00ccc7;
  float: left;
  font-size: 18px;
  box-sizing: border-box;
  position: absolute;
  margin-left: 50px;
}

.super_buyer_div {
  width: 100%;
  height: 33px;
  background: rgba(0, 204, 199, 0.1);
  border: 1px solid #00ccc7;
  box-sizing: border-box;
}

.super_buyer_text {
  top: 7px;
  line-height: 18px;
  font-family: Poppins;
}

.boleto-text {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: left;
  color: #333;
  padding-left: 30px !important;
  padding-right: 15px !important;
}

.boleto-text li {
  margin-bottom: 10px;
  line-height: 17px;
}

.autofill-taker {
  display: none;
}

button {
  cursor: pointer;
}

.dados {
  position: relative;
}

.summary_header-title {
  position: relative;
}

.summary_header-title .icon_bag {
  width: 25px;
  height: 30px;
}

.amount_produts {
  position: absolute;
  font-size: 10px;
  left: 9px;
  top: 18px;
}

.error_screen {
  text-align: center;
}

.error_screen > h3 {
  margin: 0 auto;
  margin-top: 25vh;
}

.error_screen > p {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 57px;
}

.logo_container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #d8ff6e;
  height: 50px;
}

.cart__message {
  text-align: center;
  background-color: #d8ff6e;
  height: 28px;
  width: calc(100% + 32px);
  margin-left: -16px;
}

.cart__message p {
  width: 100%;
  line-height: 28px;
  font-style: italic;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

.cart__message span {
  display: inline-block !important;
  font-style: normal !important;
}

.logo_container .logo {
  width: 125px;
  height: 33px;
}

.dados_resumo-desconto p:first-child {
  margin-right: auto;
}

.logo_container .padlock_logo {
  margin-right: 15px;
  position: absolute;
  right: 0;
}

.button__envio {
  width: 320px;
}

.dados_resumo-desconto p {
  margin-right: auto !important;
}

.fix-payments {
  width: 100%;
}

.fix-fields {
  margin: auto !important;
}

.m-t-15 {
  margin-top: 15px;
}

.empty_cart-text {
  margin: 60px auto 0px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.button_code {
  background-color: #333 !important;
  color: #ffffff !important;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  font-size: 12px !important;
}

.button_return {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  display: block;
  font-weight: bold;
  padding: 10px !important;
  width: 260px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background-color: #fdaaff;
  margin: 25px auto;
  border: 0;
}

.cart {
  max-width: 680px;
  margin: 0;
  display: block;
}

.cart__container--quantity {
  display: inline-block;
  width: 65px;
}

.cart__container-title-quantity {
  flex-direction: column;
  padding-left: 10px;
}

.fix-fields {
  color: #c31e1e !important;
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
  margin: auto;
}

.cart__container--values {
  flex-direction: row;
  width: 100%;
}

.cart__description {
  font-weight: bold;
  font-size: 14px;
  max-width: calc(100% - 20px);
  text-align: left;
}

.fa-gift {
  position: absolute;
  right: 0;
}

.fa-chevron-up,
.fa-chevron-down {
  margin-left: 10px;
}
.cart__container--image {
  position: relative;
}

.cart__container--image.mini::after {
  content: 'mini';
  background: #00ccc7;
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 16px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  border-radius: 3px 3px 0px 0px;
}

.cart__image {
  max-height: 70px;
  height: 100%;
  width: 100%;
  max-width: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 3px;
}

.cart,
.cart__info,
.cart__container-title-quantity,
.cart__container--quantity-values {
  width: 100%;
}

.cart__info {
  display: flex;
  flex-direction: row;
  justify-content: initial;
  align-items: center;
  margin-top: 15px;
  position: relative;
}

.cart__info > * {
  display: inline-flex;
}

.cart__quantity {
  position: relative;
  width: 100%;
  flex-direction: row;
}

.cart__item-price {
  margin-left: auto;
}

.cart__quantity span {
  position: absolute;
  top: 8px;
  height: 35px;
  cursor: pointer;
  width: 24px;
  font-size: 23px;
  font-family: 'Lato', sans-serif;
  line-height: 58px;
  left: 0;
  text-align: center;
}

.cart__quantity span.qty-plus {
  line-height: 32px;
  text-align: left;
  left: auto;
  right: -5px;
  top: 8px;
}

.link_cep,
.cupom_code_link {
  text-decoration: underline;
  cursor: pointer;
}

.dados_resumo-code > button {
  margin: 0 !important;
}

.dados_resumo-code > input {
  width: 168px;
  height: 40px !important;
}

.dados_resumo-code > ::placeholder {
  opacity: 1;
}

.dados_entrega--street {
  width: 100% !important;
}

.qty-plus p {
  font-size: 23px;
}

.cart__quantity span.qty-minus::before {
  content: '';
  width: 10px;
  height: 1.8px;
  position: absolute;
  top: 47%;
  left: 7px;
  display: inline-block;
  background-color: #333333;
}

input.input-text {
  background: none;
  font-family: 'Yantramanav-Black', sans-serif;
  border: none;
  padding: 10px;
  height: 60px;
  width: 80px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e7e7e7;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.cart__total-price {
  font-weight: bold;
  margin-left: 30px;
}

.cart__remove {
  position: absolute;
  right: 0;
  font-size: 12px;
  background-color: #d0d0d0;
  border-radius: 50%;
  text-align: center !important;
  color: #ffffff !important;
  width: 18px !important;
  line-height: 18px;
  height: 18px !important;
  font-family: Verdana, sans-serif;
  font-weight: bold;
}

.customer_email {
  width: 100%;
}

.dados_customer h2:first-child,
.dados_entrega h2:first-child {
  margin-top: 0;
}

.dados_pagamento h2 span:first-child p {
  font-size: 14px;
}

.dados_customer {
  box-sizing: border-box;
}

.dados_right {
  box-sizing: border-box;
}

.dados_customer_button {
  margin: 12px auto !important;
  box-sizing: border-box;
}

.dados_aguardando {
  font-size: 12px !important;
  font-style: italic;
  line-height: 17px;
  margin-left: 27px;
  font-family: 'Lato', sans-serif;
}

.dados_customer-names > label {
  text-align: left;
}

.dados_customer-names,
.dados_customer-info {
  justify-content: space-between;
}

.dados_middle,
.dados_right {
  border: 1px solid #f1e9f5;
  box-sizing: border-box;
}

.dados_entrega {
  flex-wrap: wrap;
  flex-direction: column !important;
  position: relative;
  box-sizing: border-box;
}

.dados_entrega .dados_entrega--CEP {
  width: 100%;
}

.dados_entrega .recipient_street_number {
  width: 90px;
}

.dados_customer,
.dados_pagamento,
.dados_entrega,
.dados_resumo {
  width: 100%;
}

.dados_customer,
.dados_entrega {
  border: 1px solid #f1e9f5;
  padding: 10px;
}

.dados_customer > h2,
.dados_pagamento > h2,
.dados_entrega > h2 {
  align-self: flex-start;
  font-size: 14px;
  text-align: left;
  padding-bottom: 0;
}

label {
  width: 100%;
}

.dados_customer button,
.dados_resumo button,
.finalizar_pedido button,
.button__envio,
.button_frete,
.error_screen a.boleto,
.primary-btn {
  color: #333;
  padding: 0;
  border: none;
  max-width: 320px;
  width: 100%;
  height: 40px;
  background-color: #fa8cff;
  font-size: 15px;
  margin: 16px auto;
  min-height: 40px;
  border-radius: 3px;
}
.dados_customer button:hover,
.dados_resumo button:hover,
.finalizar_pedido button:hover,
.button__envio:hover,
.button_frete:hover,
.error_screen a.boleto:hover,
.primary-btn:hover {
  background-color: #8a5eff;
  color: #fff;
  border: none;
}
.dados_customer button:focus,
.dados_resumo button:focus,
.finalizar_pedido button:focus,
.button__envio:focus,
.button_frete:focus,
.error_screen a.boleto:focus,
.primary-btn:focus {
  border: none;
  outline: none;
}

.dados_resumo-code {
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin: auto;
  margin-bottom: 8px;
  height: 65px;
  position: relative;
}

.dados_resumo-code p,
.dados_resumo-desconto p {
  font-weight: bold;
  margin-right: 8px;
  font-size: 14px;
}

.dados_resumo-desconto > p:last-child {
  font-weight: 400;
  margin-right: 17px;
}

.cupom_code_link {
  font-size: 14px;
}

.dados_resumo-code .button_code {
  width: 50px;
  margin-left: 10px !important;
}

.cupom_complement {
  position: absolute;
  bottom: 7px;
  font-style: italic;
  left: 54px;
  font-size: 12px;
  color: #fe5131;
  font-family: 'Lato', sans-serif;
}

.finalizar_pedido {
  width: 100%;
}

.finalizar_pedido button {
  height: 50px;
  margin-top: 15px;
  font-size: 18px;
}

.dados_entrega .button__change {
  align-self: flex-end;
}

.dados_resumo {
  background-color: #f9f4fa;
}

.button_frete {
  width: 50px;
  font-weight: bold;
  height: 49px;
  margin-left: 10px;
}

.button__change {
  font-size: 13px;
  text-decoration: underline black;
  cursor: pointer;
  align-self: flex-end;
}

.dados_left > *:last-child {
  margin-top: 15px;
}

.fix-fields {
  font-size: 14px;
}

.form_icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

label .fa {
  color: #fc3c19;
  position: absolute;
  right: 15px;
  top: 25px;
  font-size: 15px;
}

.links {
  margin-bottom: 10px;
}

.link {
  margin: 0 10px;
}

.tabs {
  padding: 20px;
  border: 1px solid #f5f5f5;
}

.next {
  padding: 8px 20px;
  border-radius: 3px;
  background-color: #418bca;
  outline: 0;
  display: inline-block;
  border: none;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.next_link,
.next_link_disabled {
  border-radius: 3px;
  outline: 0;
  border: none;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.next_link,
.next_link_disabled {
  background-color: #8a8a84;
}

.next_link_disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.next_link_disabled > a {
  color: #000000;
  display: inline-block;
  /* For IE11/ MS Edge bug */
  pointer-events: none;
  text-decoration: none;
}

.next_link > a,
.next_link_disabled a {
  padding: 8px 20px;
}

.summary_header {
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.summary_header span,
.dados_resumo-subtotal > p:last-child,
.dados_resumo-total > p:last-child,
.dados_resumo-desconto > p:last-child,
.dados_resumo-frete > p:last-child {
  margin-right: 0 !important;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
}

.summary_header span {
  margin-right: 0;
}

.summary_header > *:first-child {
  flex-direction: row;
  align-items: center;
}

.summary_header h2 {
  font-size: 14px;
}

.dados_pagamento > p {
  font-size: 12px;
  text-align: left;
  margin-left: 6px;
}

.dados_pagamento .dados_aguardando {
  margin-left: 27px;
}

.dados_pagamento h2 {
  justify-content: space-between;
}

.dados_pagamento h2 span {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.dados_pagamento h2 span .padlock_logo {
  margin-right: 5px;
  width: 11px;
  height: 11px;
  align-self: flex-start;
  margin-top: 2px;
}

a {
  color: inherit;
  text-decoration: none;
  /* no underline */
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.container-loading {
  display: flex;
  flex-direction: column;
  width: 100%;
}

input[type='radio'] {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Chrome, Safari, Opera */
  transform: scale(1.1);
  margin-right: 5px;
}

.text-rotator {
  margin: auto;
}

.text-rotator > span {
  font-size: 24px;
  font-weight: bold;
}

.compra-segura {
  font-size: 11px;
  font-family: 'Lato', sans-serif;
}

input[type='checkbox'] {
  height: 24px !important;
  width: 24px !important;
  -webkit-appearance: none;
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #333;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 11px !important;
  display: inline-block;
  margin-top: 0 !important;
  position: relative;
}

input[type='checkbox']:hover {
  filter: brightness(90%);
}

input[type='checkbox']:checked:after {
  font-family: 'FontAwesome';
  content: '\f00c';
  font-size: 16px;
  position: absolute;
  top: 3px;
  left: 4px;
  color: #333;
  margin-top: 0;
}

input[type='checkbox']:checked {
  color: #fff;
}

input[type='checkbox']:active,
input[type='checkbox']:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

label.customer_email-marketing {
  padding: 8px 0 0 0;
}

label.customer_email-marketing div.customer-mkt-div {
  padding: 0 0 4px 9px;
}

label.customer_email-marketing .long-text {
  display: none;
}

@media screen and (max-width: 320px) {
  label.customer_email-marketing .short-text {
    display: none;
  }

  label.customer_email-marketing .long-text {
    display: inline-block;
  }
}

label.customer_email-marketing span {
  font-size: 12px !important;
  color: #000 !important;
}

form {
  text-align: center;
}

form div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

form h2 {
  text-align: center;
  font-size: 20px;
}

form .logo {
  width: 100px;
  margin: 17px 0;
}

.search-cep {
  text-decoration: underline;
  font-size: 13px;
  margin-left: 10px;
  font-family: 'Lato', sans-serif;
}

.search-cep:hover {
  color: #9f77ff;
}

.zip_code_link {
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 6px;
  margin-left: 5px;
  font-size: 14px;
  color: #333333;
}

.zip_code_link-text {
  display: inline-block;
  font-size: 14px;
  margin-right: 2px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  min-width: 0;
}

input[type='text'],
input[type='email'],
input[type='number'] {
  height: 45px;
  border: 1px solid #999999;
  padding: 0 15px;
  margin: 8px 0;
  color: #333333;
  font-size: 14px;
}

input[type='text']:focus,
input[type='email']:focus {
  outline: none !important;
  border: 1px solid #fdaaff !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.input-text.qty {
  font-size: 15px;
  font-weight: 800;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  background: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  padding: 0px;
  width: 100%;
  text-align: center;
  background-color: white;
  height: 30px;
  width: 60px;
}

.ship_container {
  border: solid 2px #f1eaf5;
  background-color: #f9f4fa;
  margin: 16px 0 12px 0;
  width: 100%;
}

.ship_options {
  padding: 9px;
  width: 100%;
  flex-wrap: wrap;
}

.ship_options input {
  width: 13px !important;
}

.ship_options .ship_options-1st-line {
  flex-basis: 74%;
  font-weight: bold;
}

.ship_options > *:last-child {
  margin-left: 24px;
}

input::placeholder {
  opacity: 0;
}

.recipient_complement::placeholder {
  opacity: 0.5;
}

label {
  position: relative;
}

label > p {
  margin: 0;
  position: absolute;
  left: 8px;
  top: 0;
  background: white;
  padding: 0 8px;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
}

label > select {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #999999;
  height: 47px;
  padding: 10px;
}

p {
  margin: 0;
  color: #333333;
}

h2 {
  font-family: 'Poppins', sans-serif;
  color: #333333;
  display: flex;
  align-items: center;
}

span {
  font-size: 14px;
  color: #333333;
}

.ship_options {
  display: flex;
  align-items: flex-end;
}

.label_CEP {
  width: 110px;
}
.dados_entrega--info-1st {
  height: auto;
}

.dados_entrega--info-1st > div:first-child label:first-child {
  width: 110px;
}

.dados_entrega--info-1st label:last-child input {
  width: 100%;
}

.ship_options p {
  position: relative;
  left: 0;
  font-size: 14px;
  background-color: #f9f4fa;
  color: #333333 !important;
  text-align: left;
}

.price-item-free {
  background: #d8ff6c;
  font-weight: bold;
}

.dados_customer--full-description p {
  line-height: 1.5;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
}

.dados_envio--full-description p {
  line-height: 1.5;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}

.dados_envio--full-description p span {
  font-size: 13px;
}

.button__envio span {
  font-weight: bold;
}

button {
  font-family: 'Poppins', sans-serif;
}

.dados_entrega--full-description {
  width: calc(100% - 26px) !important;
}

.bold-text {
  font-weight: bold;
}

.dados_resumo-frete {
  margin-top: 0;
}

.dados_resumo-frete p {
  font-weight: normal !important;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.dados label p {
  color: #999999;
}

.customer_email-marketing {
  font-size: 12px;
  color: #000;
  text-align: left;
  font-family: 'Lato', sans-serif;
  align-items: center;
}

.dados_pagamento_options {
  width: 100%;
}

.dados_entrega--info-2st {
  width: 100%;
}

.dados_pagamento_options .credit_card-option {
  width: 60%;
  border-right: 0;
}

.dados_pagamento_options .boleto-option {
  width: 40%;
}

.dados_pagamento_options label {
  padding-left: 5px;
  display: flex;
  align-items: center;
  border: 2px solid #f1eaf5;
  font-weight: bold;
  font-size: 14px;
  width: 170px;
  height: 40px;
  font-family: 'Poppins', sans-serif;
}

.loading-gif {
  display: block;
  width: 300px;
  margin: 100px auto !important;
}

.dados_entrega--full-description {
  margin: 0 0 10px 0;
}

.zip_code_link {
  padding-left: 0 !important;
}

.dados_container_left-middle {
  margin-left: 40px;
}

.dados_pagamento_options {
  box-sizing: border-box;
}

.dados_entrega--full-description {
  width: calc(100% - 26px);
}

.dados_resumo-subtotal {
  margin-top: 10px;
}

.dados_resumo-total {
  margin-top: 0;
}

.cart__description,
.cart__total-price,
.cart__item-price,
.cart__quantity quantity {
  font-family: 'Poppins', sans-serif;
}

.dados_container_left-middle > h2:first-child {
  padding-left: 37px;
  font-family: 'Poppins', sans-serif;
}

.cart__container-title-quantity {
  height: 70px;
}

.dados_customer--full-description {
  margin: 0;
  margin-top: 0;
  margin-left: 28px;
  margin-bottom: 8px;
}

.dados_container_left-middle h2 {
  flex-basis: 100%;
  text-align: left;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  display: inline-flex;
  box-sizing: border-box;
}

.code-input {
  text-transform: uppercase;
}

.code-input::placeholder {
  text-transform: lowercase;
  color: #c4c3c3;
}

.dados_pagamento_card--installments {
  margin-top: 7px;
}

.dados_pagamento h2 {
  width: 100%;
}

.customer_email-marketing {
  margin-bottom: 2px;
}

.dados_entrega--info-1st > div:last-child {
  width: calc(100% - 120px) !important;
}
.recipient_complement--label,
#recipient_complement {
  width: 100%;
}

.customer__email__typo {
  width: 95%;
  right: 0;
  left: unset;
  top: -30px;
  color: #c31e1e !important;
  font-size: 12px;
  text-align: left;
  margin-bottom: 6px;
}

.customer__email__typo--answer {
  cursor: pointer;
  font-size: 12px;
  padding: 3px;
  margin-left: 10px;
  color: #c31e1e !important;
}

.customer__email__typo--answer:hover {
  color: #7e1717 !important;
}

.customer__email__typo--underline {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  color: #c31e1e !important;
}

@media (min-width: 545px) and (max-width: 640px) {
  .customer_email-marketing {
    line-height: 30px;
  }
}

@media (max-width: 640px) {
  .dados_entrega .button__envio {
    max-width: unset;
    margin-top: 16px;
  }

  .dados_container_left-middle > h2:first-child {
    padding-left: 0;
  }

  .customer__email__typo {
    width: 95%;
    right: 0;
    left: unset;
    top: -30px;
    color: #c31e1e !important;
    margin-bottom: 10px;
  }

  .empty_cart-text {
    margin-top: 110px;
  }

  .dados_resumo-subtotal > p:last-child,
  .dados_resumo-frete > p:last-child,
  .dados_resumo-total > p:last-child,
  .dados_resumo-desconto > p:last-child {
    margin-right: 19px !important;
  }

  .zip_code_link-text {
    margin: 0 !important;
    margin-left: 26px !important;
  }

  .dados_entrega--info-1st {
    flex-wrap: wrap;
  }

  .dados_resumo-code {
    width: calc(100% - 20px);
    margin: 0 0 0 20px;
  }

  .dados_resumo-code > input {
    width: 145px;
    height: 40px !important;
  }

  .error_screen > h3 {
    margin: 0 40px;
    margin-top: 16vh;
    font-size: 14px;
  }

  .error_screen > p {
    font-size: 13px;
  }

  .error_screen > .button_return {
    font-size: 15px;
    width: 200px;
    height: 32px;
    line-height: 32px;
  }

  .empty_cart-text {
    text-align: center;
  }

  .recipient_street_number--label {
    width: auto;
  }

  .cart__message {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }

  .summary_header {
    box-sizing: border-box;
    padding-right: 15px;
  }

  .dados_container_left-middle {
    margin-left: 0;
  }

  .dados {
    margin: 0 10px;
  }

  .dados_entrega--full-description {
    margin-left: 26px;
  }

  .logo_container {
    justify-content: space-between;
  }

  .loading-gif {
    display: block;
    margin-top: 140px !important;
    margin: auto;
    width: 150px;
  }

  .logo_container > a {
    margin: auto;
  }

  .cart {
    padding-right: 15px;
    box-sizing: border-box;
  }

  p {
    font-size: 12px;
    text-align: left;
  }

  .button__change {
    position: absolute;
    top: 11px;
    right: 11px;
  }

  .dados_customer,
  .dados_entrega,
  .dados_pagamento {
    position: relative;
    padding: 0;
    align-items: center;
  }

  .dados_entrega > *,
  .dados_pagamento > * {
    padding: 10px;
    margin: 0;
  }

  .dados_container_left-middle {
    width: 100%;
  }

  .dados_container_left-middle > h2:first-child {
    align-self: center;
    font-family: 'Poppins', sans-serif;
    margin-top: 65px;
  }

  .dados_customer h2 {
    padding: 10px;
    margin: 0;
  }

  .dados_customer label {
    width: 95%;
  }

  .dados_customer--full-description {
    align-self: flex-start;
    margin-left: 37px;
    padding-bottom: 9px;
  }

  .dados_customer-names,
  .dados_customer-info {
    width: 95%;
  }

  .dados_customer-names > label,
  .dados_customer-info > label {
    width: 100%;
  }

  .dados_left label {
    display: flex;
  }

  .dados_entrega--info-1st label p,
  .dados_entrega--info-2st label p,
  .dados_pagamento_card label p {
    top: 1px;
  }

  .dados_left input {
    width: 100%;
  }

  .dados_left {
    width: 100%;
  }

  .dados_middle,
  .dados_right {
    padding: 0;
    width: 100%;
    margin-top: 15px;
  }

  .dados_aguardando {
    margin-left: 26px;
    margin-bottom: 6px;
    padding-top: 3px;
  }

  .dados_pagamento > .dados_aguardando {
    margin-left: 27px;
  }

  form div {
    align-items: flex-start;
  }

  .dados_entrega--info,
  .dados_entrega--info > * {
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
  }

  .dados_entrega--info-1st {
    flex-direction: row;
    margin-top: 14px;
  }

  .dados_entrega--info-1st > *:last-child {
    width: 100%;
    margin-left: 10px;
  }

  .dados_entrega--info-2st,
  .dados_entrega--full-name {
    width: 100%;
  }

  .button__envio {
    max-width: 320px;
    margin: 0;
    align-self: center;
  }

  .dados_pagamento_options {
    align-self: flex-start;
    flex-direction: row;
  }

  .dados_pagamento_card {
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 0 5px;
    margin-bottom: 8px;
  }

  .dados_pagamento_card > label,
  .dados_pagamento_card input {
    width: 97%;
    display: flex;
  }

  .dados_pagamento_card--sub-container-1st {
    flex-direction: row;
    align-items: center;
    width: 97%;
    justify-content: space-between;
  }

  .dados_pagamento_card--sub-container-1st > label,
  .dados_pagamento_card--sub-container-2nd > label {
    margin-right: 5px;
  }

  .dados_pagamento_card--sub-container-1st > label:last-child,
  .dados_pagamento_card--sub-container-2nd > label:last-child {
    margin-right: 0;
  }

  .dados_pagamento_card--sub-container-1st > select {
    height: 46px;
  }

  .dados_pagamento_card--sub-container-2nd {
    flex-direction: row;
    width: 97%;
    justify-content: space-between;
  }

  .dados_pagamento_card--sub-container-2nd > label {
    display: flex;
  }

  .dados_pagamento_card--sub-container-2nd > label:last-child {
    flex-basis: 100%;
  }

  .card_cvv {
    flex-basis: 65%;
    display: flex;
  }

  .card_cvv > input {
    min-width: 0;
  }

  label > select {
    height: 47px;
  }

  .dados_pagamento_card--installments select {
    width: 100%;
  }

  .dados_pagamento_card input {
    width: 100%;
  }

  .logo_container {
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: 9999;
    width: 100%;
  }

  .logo_container .logo {
    align-self: center;
    margin: 0 auto;
  }

  .logo_container .padlock_logo {
    align-self: flex-end;
    margin: 0;
    top: 15px;
    right: 12px;
  }

  .label_CEP p {
    left: 10px;
    top: 2px;
  }

  .dados_pagamento_card--installments p,
  .month_input p,
  .year_input p {
    top: -6px !important;
  }

  .dados_envio--full-description {
    margin-left: 28px;
  }

  .dados_pagamento .dados_aguardando {
    align-self: flex-start;
  }

  .summary_header .summary_header-title {
    margin: 7px 16px;
  }

  .cart__container--quantity-values {
    flex-direction: row;
    align-items: center;
  }

  .cart__item-price,
  .cart__total-price {
    font-size: 14px;
    margin-left: auto;
    line-height: 30px;
  }

  .cart__item-price {
    text-align: center;
  }

  .cart__total-price {
    margin-left: auto;
  }

  .cart__container-title-quantity {
    flex-direction: column;
  }

  .cart__description {
    align-self: flex-start;
  }

  .dados_customer button,
  .dados_resumo button,
  .finalizar_pedido button {
    width: 95%;
    max-width: unset;
  }

  .dados_right {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-top: 15px;
  }

  .cart__container--image {
    margin-left: 15px;
  }

  .cart__container--quantity-values {
    justify-content: space-between;
  }

  .dados_resumo-subtotal,
  .dados_resumo-frete,
  .dados_resumo-total,
  .dados_resumo-desconto {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .dados_resumo-subtotal > p,
  .dados_resumo-frete > p,
  .dados_resumo-total > p {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .dados_resumo-total p span {
    display: block;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    text-align: right;
    font-family: 'Lato', sans-serif;
  }

  .dados_resumo-subtotal > p:first-child,
  .dados_resumo-frete > p:first-child,
  .dados_resumo-total > p:first-child,
  .dados_resumo-desconto > p:first-child {
    margin-left: 19px;
  }

  .dados_resumo-frete p span {
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    width: 100%;
  }

  .dados_resumo-frete p span i {
    font-weight: 600;
    color: #333;
  }

  .dados_resumo-total > p:first-child,
  .dados_resumo-total > p:last-child {
    font-size: 17px;
  }

  .dados_resumo-total > p:last-child {
    text-align: right;
  }

  .dados_resumo-frete p:last-child {
    width: 25%;
    text-align: right;
  }

  .dados_pagamento_options {
    padding: 0 9px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .dados_pagamento_options > label {
    padding-right: 0px;
    padding-left: 8px;
  }

  .dados > .empty_cart-text {
    margin: 170px auto !important;
    font-size: 12px;
  }
}

@media (min-width: 641px) {
  .dados > .empty_cart-text {
    margin: 170px auto !important;
    font-size: 18px;
  }

  .fa-chevron-up,
  .fa-chevron-down {
    display: none;
  }

  .button_return {
    font-size: 16px;
  }

  .cupom_complement {
    top: 41px;
    width: 100%;
    text-align: left;
  }

  .dados_resumo .dados_resumo-subtotal,
  .dados_resumo .dados_resumo-frete,
  .dados_resumo .dados_resumo-total,
  .dados_resumo-code,
  .dados_resumo-desconto {
    width: 280px !important;
  }

  .dados_envio--full-description {
    margin-left: 27px;
  }

  .dados_envio--full-description p {
    font-size: 13px;
  }

  .dados_entrega--info {
    padding-top: 0;
  }

  .dados_pagamento_options {
    width: 100%;
    flex-direction: row;
    margin: 20px 0;
  }

  .dados_resumo .dados_resumo-subtotal,
  .dados_resumo .dados_resumo-frete,
  .dados_resumo .dados_resumo-total,
  .dados_resumo-desconto {
    flex-direction: row;
    text-align: left;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .dados_resumo-subtotal > p:first-child,
  .dados_resumo-total > p:first-child {
    font-weight: bold;
    margin-right: auto;
    font-size: 14px;
  }

  .dados_resumo-frete {
    justify-content: space-between;
  }

  .dados_resumo-total > p {
    font-weight: bold;
    font-size: 18px;
  }

  .dados_resumo p span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Lato', sans-serif;
  }

  .dados_resumo p span i {
    font-weight: 600;
    color: #333;
  }

  .dados_resumo-frete p:first-child {
    font-size: 14px;
  }

  .dados_entrega--info-1st {
    flex-wrap: wrap;
  }
  .dados_entrega--info-1st > div:last-child {
    flex-grow: 1;
  }

  .dados_entrega--street {
    margin-right: 0 !important;
  }

  .dados_entrega--street input {
    width: 100%;
  }

  .dados_resumo-frete p span {
    display: block;
  }

  .recipient_street_number--label {
    width: auto;
  }

  .boleto-text {
    width: 100%;
  }

  .cart {
    margin-bottom: 20px;
  }

  .cart__container--quantity-values {
    align-items: center;
    flex-direction: row;
  }

  .cart__item-price,
  .cart__total-price {
    font-size: 14px;
    line-height: 30px;
  }

  .dados {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
  }

  .dados_customer {
    position: relative;
  }

  .dados_customer-names,
  .dados_customer-info {
    flex-direction: row;
    width: 100%;
  }

  .dados_customer-names > label,
  .dados_customer-info > label {
    width: 49%;
  }

  .dados_customer-names label input,
  .dados_customer-info label input {
    min-width: 0;
    width: 100%;
  }

  .dados_pagamento {
    flex-direction: column;
  }

  .dados_container_left-middle {
    flex-basis: 66%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 0;
  }

  .dados_right {
    flex-basis: 33%;
    background-color: #f9f4fa;
    height: 100vh;
  }

  .dados_pagamento h2 {
    margin: 0;
  }

  .dados_pagamento,
  .dados_entrega {
    width: 100%;
  }

  .dados_entrega--info {
    width: 100%;
  }

  .dados_entrega--info-1st,
  .dados_entrega--info-2st {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .dados_entrega--info-1st > *,
  .dados_entrega--info-2st > * {
    margin-right: 10px;
    display: flex;
  }

  .dados_entrega--info-2st input {
    width: 100%;
  }

  .dados_entrega--info-1st > *:first-child {
    margin-left: 0;
  }

  .dados_entrega--info-1st > *:last-child,
  .dados_entrega--info-2st > *:last-child {
    margin-right: 0;
  }

  .dados_entrega .button_frete {
    margin: 0;
    margin-top: 0px;
    margin-left: 10px;
    height: 49px;
    width: 49px;
  }

  .dados_entrega--full-name {
    width: 100%;
  }

  .dados_middle,
  .dados_right {
    padding: 12px;
  }

  .dados_left {
    width: calc(50% - 40px);
  }

  .dados_right {
    margin-right: 0;
    padding: 15px;
  }

  .dados_middle {
    width: calc(50% - 40px);
    margin-right: 40px;
  }

  .dados_customer h2 {
    margin-bottom: 5px;
  }

  .dados_customer--full-description p,
  .dados_entrega--full-description {
    font-size: 13px;
    text-align: left;
    font-family: 'Lato', sans-serif;
  }

  .dados_entrega--full-description {
    font-size: 14px;
  }

  .dados_pagamento .dados_aguardando {
    margin-top: 10px;
    font-size: 14px;
  }

  .dados_entrega--full-description,
  .zip_code_link-text {
    padding-left: 26px;
  }

  .dados_envio--full-description p {
    text-align: left;
  }

  .button__change {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .dados_pagamento_options {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .dados_pagamento_options > * {
    text-align: left;
  }

  .dados_pagamento_card {
    width: 100%;
  }

  .dados_pagamento_card label {
    width: 100%;
  }

  .dados_pagamento_card label input {
    /* width: 100%; */
    min-width: 0;
  }

  .month_input,
  .year_input {
    width: 33.33% !important;
    margin-right: 10px;
  }

  .card_cvv {
    width: 33.33% !important;
  }

  .dados_pagamento_card--sub-container-1st,
  .dados_pagamento_card--sub-container-2nd {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .dados_pagamento_card--sub-container-2nd label input {
    /* width: 90%; */
  }

  .dados_pagamento-header {
    flex-direction: row;
    text-align: left;
    margin-bottom: 10px;
  }

  .dados_pagamento_card--sub-container-1st > *:last-child,
  .dados_pagamento_card--sub-container-2nd > *:last-child {
    margin-left: 10px;
  }

  .dados_pagamento_card--installments,
  .dados_pagamento_card--installments select {
    width: 100%;
  }

  label {
    display: flex;
  }

  .card_birthday {
  }
}

input[name='card_name'] {
  text-transform: uppercase;
}

label.flex\:col {
  display: flex;
  flex-direction: column;
}

label.has-legend input {
  margin: 8px 0 0;
}

label.has-legend .legend,
.is-legend .legend {
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  font-style: italic;
  margin: 0 16px 8px;
  display: block;
  text-align: left;
  color: #999999;
}

label.has-validation input.invalid, select.invalid {
  border-color: #fc3c19;
  padding: 0 37px 0 15px;
}

label.has-validation .invalid,
label.has-validation.has-legend .legend.invalid,
.is-legend .legend.invalid {
  color: #fc3c19;
}

.is-legend {
  margin-top: -8px;
  width: 100%;
}

@media (min-width: 1201px) {
  .dados_resumo .dados_resumo-subtotal,
  .dados_resumo .dados_resumo-frete,
  .dados_resumo .dados_resumo-total {
    width: 60%;
  }
}

@media (min-width: 641px) and (max-width: 979px) {
  .dados_container_left-middle {
    margin-left: 10px;
  }

  .dados_left {
    margin-right: 10px;
  }

  .dados_middle {
    width: 100%;
    margin-right: 10px;
    margin-top: 15px;
  }

  .select_label {
    left: 2px;
  }

  .dados_left {
    flex-basis: 100%;
  }

  .cart__item-price,
  .cart__total-price {
    min-width: 63px;
  }

  .cart__total-price {
    margin-left: 0;
  }

  .price-item-free {
    min-width: unset;
    margin-right: 10px;
  }
}

@media (min-width: 980px) and (max-width: 1279px) {
  .dados_container_left-middle {
    margin-left: 10px;
  }

  .dados_left {
    width: calc(50% - 30px);
  }

  .dados_middle {
    width: calc(50% - 30px);
    margin-right: 30px;
  }

  .select_label {
    left: 2px;
  }
}

.select_label {
  top: -8px;
}

select {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  border-radius: 0 !important;
  border-style: solid;
  padding-left: 11px !important;
}

select:active {
  border: 2px solid #fdaaff !important;
}

select,
select option {
  color: #000000;
}

select:invalid,
select option[value=''] {
  color: #adadad;
}

[hidden] {
  display: none;
}

.no-margin {
  margin: 0 !important;
}

.box-flex__margin-top-bottom {
  display: flex;
  margin: 0 0 0 30px;
  flex-direction: row;
}

.box-flex__description-button {
  margin-left: 23px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: flex-end;
}

.font-light {
  font-weight: 300;
}

.mgm-btn {
  background-color: #fdaaff;
  border: none;
  border-radius: 3px;
  height: 46px;
  width: 260px;
  font-weight: 600;
  padding: 10px 40px;
}

.mgm-btn.buy-button {
  width: 100%;
  padding: 0% 8%;
  font-size: 0.85rem;
  color: #000;
  border-radius: 0px;
}

.mgm-btn.buy-button:hover {
  background-color: #9f77ff;
  transition: all 250ms ease-out;
}

.sm-title {
  font-size: 0.85rem;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
}

.font-light {
  font-size: 0.85rem;
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: left;
}

.image-width {
  max-width: 134px;
  height: 134px;
}

.show-mobile {
  display: none;
}

.upsell-module {
  margin: 25px auto 0px auto;
  display: flex;
  flex-direction: row;
}

.divisoria {
  margin: 15px auto;
  max-width: 662px;
  width: 100%;
  border: 1px solid #d0d0d0;
}

.promo-description {
  max-width: 185px;
}

.show-mobile.center {
  margin: 0 auto;
}

.show-mobile.center > img {
  width: 100%;
  margin-top: 25px;
}

@media (max-width: 599px) {
  .sm-title {
    margin: 0px auto 5px 0;
  }

  .font-light {
    margin: 0 auto 8px 0;
  }

  .sm-title,
  .font-light,
  .mgm-btn.buy-button {
    font-size: 0.75rem;
  }

  .image-width {
    max-width: 116px;
    height: 116px;
  }

  .box-flex__description-button {
    margin-left: 15px;
    align-self: flex-start;
  }

  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none;
  }

  .box-flex__margin-top-bottom {
    margin-left: 0;
  }

  .divisoria {
    margin: 5px auto;
  }

  .upsell-module {
    margin-top: 10px;
  }
}

.error_screen .title {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  margin: 48px auto 16px;
}

.error_screen .linha-fina {
  font-family: 'Poppins', sans-serif;
  margin: 12px auto;
  max-width: 600px;
}

.error_screen .dados_right {
  margin-bottom: 24px;
}

.error_screen .dados_customer,
.error_screen .dados_entrega,
.error_screen .dados_right {
  width: 30%;
  margin-bottom: 12px;
  cursor: default;
}

div.input-text.qty {
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 8px 0;
}

.error_screen a.boleto {
  margin-bottom: 32px;
  line-height: 2.5;
}

@media (max-width: 1300px) {
  .error_screen .dados_customer,
  .error_screen .dados_entrega,
  .error_screen .dados_right {
    width: 50%;
  }
}

@media (max-width: 599px) {
  .error_screen .title {
    font-size: 18px;
    margin: 64px auto 16px;
  }

  .error_screen .linha-fina {
    font-family: 'Poppins', sans-serif;
    margin: 0 16px 16px;
  }

  .error_screen .dados_customer,
  .error_screen .dados_entrega,
  .error_screen .dados_right {
    width: 100%;
    margin: 0 0 16px;
  }

  .error_screen a.boleto {
    margin: 0 24px 36px;
    width: calc(100% - 48px);
  }
}

.covid-message {
  padding: 10px 12px;
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  color: #333;
  font-family: 'Lato';
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  line-height: 150%;
  width: -moz-available;
  width: -webkit-fill-available;
}

.lato-bold {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000 !important;
}

.lato-italic {
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000 !important;
}

.text-checkbox {
  margin-left: 8px;
  margin-top: -4px;
}

.customer_email-marketing.gift {
  align-items: flex-start;
}
.full-empty {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1440px;
  position: absolute;
  left: 0;
  z-index: 8000;
  background-color: white;
  height: 100vh;
  top: 0;
}

.full-empty .first-section,
.full-empty .second-section {
  flex-basis: 55%;
  align-items: center;
  height: 100vh;
}
.full-empty .second-section {
  flex-basis: 45%;
  border-left: 1px solid #f1eaf5;
}
.product_item--thumbnail {
  position: relative;
  z-index: 2;
}
.product_item--inner:hover .product_item--thumbnail-holder > a.globo-add-to-cart {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}
.product_item--thumbnail {
  overflow: hidden;
}
.product_item--thumbnail img {
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18);
}

.product_item--thumbnail .wp-alt-image {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18);
  opacity: 0;
}

.product_item--thumbnail:hover .wp-alt-image {
  opacity: 1;
}
.product_item--info .product_item--info-inner {
  display: flex;
  flex-flow: column;
}
.product_item--info .product_item--info-inner > * {
  order: 20;
}
.product-item__info {
  margin: 0px 0px 0px 24px;
  text-align: left;
  align-self: flex-start;
  font-family: 'Poppins', sans-serif;
}

.product-item__info.title {
  order: 1;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
}

.product-item__info.description {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 1;
  margin-top: 12px;
  order: 2;
}

.product-item__info.preco {
  font-size: 20px;
  line-height: 1;
  margin-top: 21px;
  order: 3;
  font-weight: 700;
}

.product-item__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 4;
  border-radius: 3px;
  height: 56px;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  align-self: center;
  margin-top: 21px;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
}
.full-empty .product-item__btn:hover {
  background-color: #8a5eff;
  color: #fff;
}
.woocommerce-mini-cart__empty-message {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-top: 60px;
  padding-top: 0;
  line-height: 1.3;
  text-align: left;
}
.woocommerce-mini-cart__empty-message span {
  font-size: 15px;
  font-family: Lato;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 16px;
  text-align: left;
}

.button_empty-cart {
  border-radius: 3px;
  display: inline-block;
  width: calc(100% - 6px);
  max-width: 297px;
  height: 50px;
  line-height: 50px;
  color: #333;
  background-color: transparent;
  border: 3px solid #333;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 40px auto 0 0;
}
.button_empty-cart:hover {
  background: #333;
  color: #fff !important;
}
.cart-title-upsell {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  margin: 60px auto 24px 0;
}
.full-empty
  .sidebar-right
  .sidebar__inner
  .product_item--inner
  .product_item--thumbnail
  .product-item__info.description {
  margin-top: 4px;
  font-size: 14px;
}
.full-empty .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail .product-item__info.preco {
  margin-top: 8px;
}
.full-empty .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail {
  align-items: center;
  display: flex;
}

.full-empty .sidebar-right .sidebar__inner .product-item__btn {
  margin-top: 24px;
}
.full-empty .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail img {
  max-width: 150px;
}
.text-divided_price {
  font-size: 15px;
  font-weight: 500;
}

@media (max-width: 899px) {
  .sidebar__inner {
    max-width: 300px;
  }
  .full-empty .first-section > div {
    max-width: 330px;
  }
  .full-empty .sidebar__inner .product_item--inner {
    padding: 0;
  }
  .full-empty .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail .product_item--info {
    margin-top: 0;
  }
}
@media (min-width: 700px) {
  .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail {
    display: flex;
    flex-direction: row;
  }
  .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail .product-item__info.title {
    font-size: 18px;
    line-height: 1.2;
  }
  .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail .product-item__info.description {
    font-size: 15px;
    margin-top: 8px;
  }
  .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail .product-item__info.preco {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 1.3;
  }
}
@media (max-width: 699px) {
  .full-empty {
    flex-direction: column;
    height: fit-content;
    margin-top: 0;
    margin-bottom: 24px;
  }
  .full-empty .first-section,
  .full-empty .second-section {
    flex-basis: auto;
    align-items: center;
    border-left: none;
    margin-left: 16px;
    margin-right: 16px;
    height: auto;
  }
  .sidebar__inner .product_item--inner {
    padding: 0 16px;
  }
  .button_empty-cart {
    margin: 16px auto 0;
  }
  .woocommerce-mini-cart__empty-message {
    font-size: 18px;
    text-align: center;
  }
  .woocommerce-mini-cart__empty-message span {
    text-align: center;
  }
  .cart-title-upsell {
    margin-top: 50px;
    text-align: left;
  }
  .full-empty .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail {
    flex-direction: row;
  }
  .full-empty .sidebar-right .sidebar__inner .product_item--inner .product_item--thumbnail img {
    max-width: 140px;
  }
  .product-item__info {
    margin: 0px 0px 0px 16px;
  }
}

#charcount_number,
#charcount_complement {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #999999;
  line-height: 14px;
  margin-top: -8px;
}

.modal-show {
  display: flex;
}
.modal-hide {
  display: none;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 90%;
  margin: auto;
  padding: 64px 80px;
  border-radius: 5px;
  background: #fff;
}
.modal-title {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 24px;
}
.modal-text {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 24px;
}
.modal-text:last-of-type {
  margin-bottom: 16px;
}
.modal-text.text-hide {
  display: none;
}
.primary-btn {
  padding: 16px 48px;
  border-radius: 3px;
  line-height: 1.3;
  font-weight: bold;
  height: auto;
  margin-top: 0;
  max-width: 400px;
  font-size: 16px;
}
.primary-btn:hover {
  background-color: #8a5eff;
  color: #fff;
  border: none;
}
.secondary-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #333;
  font-weight: bold;
  border: 3px solid #333;
  padding: 16px 48px;
  line-height: 1.3;
  border-radius: 3px;
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  font-size: 16px;
}

.secondary-btn:last-of-type {
  margin-bottom: 0;
}
.secondary-btn:hover {
  background-color: #333;
  color: #fff;
}
.secondary-btn svg {
  margin-right: 16px;
}
.secondary-btn:hover svg path {
  fill: #fff;
}

@media (max-width: 599px) {
  .primary-btn,
  .secondary-btn {
    padding: 14px 48px;
  }
  .modal {
    padding: 40px 16px;
  }
}
.progress-btn {
  transition: all 0.4s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-btn:not(.active) {
  cursor: pointer;
}
.progress-btn .progress {
  width: 0%;
  z-index: 5;
  background-color: rgb(253, 133, 253, 1);
  opacity: 0;
  transition: all 0.3s ease;
}
.progress-btn strong {
  z-index: 10;
}
.progress-btn.active .progress {
  opacity: 0.5;
  /* transition: width 10s ease-in; */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.progress-btn:disabled {
  background-color: rgb(253, 133, 253, 0.4);
  color: rgb(0, 0, 0, 0.4);
  pointer-events: none;
}
.progress-btn svg {
  margin-left: 16px;
}
  .recipient_complement {
    width: 100%;
  }
@media (max-width: 1024px) {
  .dados_pagamento_card--sub-container-2nd {
    flex-direction: column !important;
  }

  .dados_pagamento_card--sub-container-2nd > *:last-child {
    margin: 0 !important;
  }

  .dados_pagamento_card input:not(.cvv_input) {
    width: auto !important;
  }

  .final-payment-error h3 {
    font-size: 18px;
  }
}

@media (min-width: 1024px){
  .dados_pagamento_card input:not(.cvv_input) {
    width: auto !important;
  }

  .dados_pagamento_card .dados_pagamento_card--sub-container-2nd input {
    width: calc(100% - 32px) !important;
  }

  .dados_pagamento_card .dados_pagamento_card--sub-container-2nd input.invalid {
    width: calc(100% - 54px) !important;
  }
}

@media (min-width: 981px) and (max-width: 1160px) {
  .select_label {
    padding: 0 4px;
  }
}


.error-container {
  max-width: 600px;
  margin: 0 auto;
}
.error-container h3 {
  margin-top: 15vh;
}
.error-container span {
  font-size: 45px;
  margin-bottom: 16px;
}

.final-payment-error {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #333;
  max-width: 500px;
  display: block;
  margin-top: 10vh;
}

.final-payment-error h3 {
  margin: 32px 16px;
}
